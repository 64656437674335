import React from "react";
import PropTypes from "prop-types";
import ProjectTags from "./components/ProjectTags";
import "./detailed-project.scss";

const DetailedProject = ({ project }) => {
  const { title, subtitle, tags, time, background, projectInfo } = project;
  return (
    <div className="detailed-project__main-container">
      <div className="detailed-project__hero-container">
        <div className="max-width__container">
          <div className="gutters">
            <div className="project-info__container">
              <div>
                <h2 className="light-weight">{title}</h2>
                <h4 className="light-weight">{subtitle}</h4>
                <ProjectTags tags={tags} />
                <p className="info-text reading-time">{`${time} min read`}</p>
              </div>
              <div className="background-container">
                <span className="background__title">Background</span>
                <p className="background__info">{background}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="detailed-project__info">
        <div className="max-width__container">
          <div className="gutters">{projectInfo}</div>
        </div>
      </div>
    </div>
  );
};

DetailedProject.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    tag: PropTypes.string,
    time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    link: PropTypes.string,
  }).isRequired,
};

export default DetailedProject;
