import React from "react";
import { NavLink } from "react-router-dom";
import useScrollPosition from "../../utils/customHooks/useScrollPosition";
import Link from "../Link/Link";
import "./nav-bar.scss";

const NavBar = () => {
  const getScrollPosition = useScrollPosition();

  return (
    <nav
      className={`navbar__container ${
        getScrollPosition > 32 ? "box-shadow" : ""
      }`}
    >
      <div className="max-width__container">
        <div className="gutters">
          <ul>
            <Link href="/" className="nav__link logo">
              Gemma Enriquez
            </Link>
            <div className="navbar__links">
              <NavLink to="/about" className="link nav__link">
                About
              </NavLink>

              <Link
                href="https://www.linkedin.com/in/gemenri/"
                className="nav__link"
                internal={false}
              >
                LinkedIn
              </Link>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
