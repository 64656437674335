import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./projects-grid-item.scss";

const ProjectsGridItem = ({ project }) => {
  const { id, title, subtitle, time, link } = project;
  return (
    <Link
      to={link}
      className="projects__grid-item"
      id={`projects__grid-item-${id}`}
    >
      <div className="project__grid-item-content">
        <h4>{title}</h4>
        <p className="project__grid-item-content__tag">{subtitle}</p>
        <span className="info-text">{`${time} min read`}</span>
      </div>
    </Link>
  );
};

ProjectsGridItem.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    tag: PropTypes.string,
    time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    link: PropTypes.string,
  }).isRequired,
};

export default ProjectsGridItem;
