import React from "react";
import { projects } from "../../../utils/constants/projects";
import ProjectsGridItem from "./components/ProjectsGridItem";
import "./projects.scss";

const Projects = () => {
  return (
    <div className="projects__main-container">
      <div className="max-width__container">
        <div className="gutters">
          <h2 className="section-title">Projects</h2>
          <div className="projects__grid">
            {projects.map((project) => {
              const { id } = project;
              return <ProjectsGridItem key={id} project={project} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
