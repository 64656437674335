import React from "react";
import PropTypes from "prop-types";
import Link from "../../../../components/Link/Link";
import "./article-card.scss";

const ArticleCard = ({ article }) => {
  const { title, description, link, image } = article;
  return (
    <div className="article-card__container">
      <div className="card__info">
        <h4 className="card-title">{title}</h4>
        <p className="card-description">{description}</p>
        <Link href={link} className="card-link" icon={true} internal={false}>
          Read full article
        </Link>
      </div>
      <a href={link} target="_blank" rel="noreferrer">
        <div className="card-image__container" id={`${image}__container`}>
          <img
            className="card-image"
            id={`card-image__${image}`}
            src={`images/articles/${image}.png`}
            alt="Headshot of Gemma Enriquez"
          />
        </div>
      </a>
    </div>
  );
};

ArticleCard.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
};

export default ArticleCard;
