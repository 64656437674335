import React from "react";
import PropTypes from "prop-types";
import colors from "../../styles/_colors.scss";

const Link = ({ size, color }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Desktop-Copy-6" transform="translate(-363.000000, -3560.000000)">
          <g id="Group" transform="translate(213.000000, 3560.000000)">
            <g id="Group-9" transform="translate(150.000000, 0.000000)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <line
                x1="10"
                y1="13.8208612"
                x2="18"
                y2="6.10657552"
                id="Path-3"
                className="link-icon"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
              ></line>
              <polyline
                id="Path-6-Copy"
                className="link-icon"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(18.000000, 6.000000) rotate(-90.000000) translate(-18.000000, -6.000000) "
                points="15 9 21 9 21 3"
              ></polyline>
              <polyline
                id="Path-7"
                className="link-icon"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="10.5 6 3 6 3 21 18 21 18 13.5"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Link.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

Link.defaultProps = {
  size: 24,
  color: colors.ctaColor,
};

export default Link;
