import React from "react";
import Hero from "./Hero/Hero";
import Welcome from "./Welcome/Welcome";
import Projects from "./Projects/Projects";
import About from "./About/About";
import Footer from "../../components/Footer/Footer";

const Home = () => {
  return (
    <div>
      <Hero />
      <Welcome />
      <Projects />
      <About />
      <Footer type="home" />
    </div>
  );
};

export default Home;
