import React from "react";
import "./project-tag.scss";
import generateRandomKey from "../../../utils/functions/generateRandomKey";

const ProjectTags = ({ tags }) => {
  return (
    <div className="tags__container">
      {tags.map((tag) => {
        return (
          <span key={generateRandomKey()} className="tag info-text">
            #{tag}
          </span>
        );
      })}
    </div>
  );
};

ProjectTags.propTypes = {};

export default ProjectTags;
