import React from "react";
import "./marshmallow-challenge.scss";

const MarshmallowChallenge = () => {
  return (
    <div className="marshmallow-project__info-container">
      <div className="">
        <div className="">
          <h3>WHAT?</h3>
          <h4>👀​ Yes, I said marshmallow</h4>
          <p>
            The Marshmallow Challenge is a design challenge introduced by Peter
            Skillman and popularized by Tom Wujec.
          </p>
          <p>
            Teams of four have to build the tallest freestanding structure in 18
            minutes using 20 sticks of spaghetti, one yard of tape, one yard of
            yarn, and a marshmallow. The only other rule is that the marshmallow
            must sit on top of the structure. It's a simple challenge, but
            teachings from this challenge are deep and insightful for both
            designers and non-designers.
          </p>
        </div>
        <img
          src="images/marshmallow-challenge/what-section.png"
          alt="Headshot of Gemma Enriquez"
        />
      </div>
      <h3>WHY?</h3>
      <h4>There are two key lessons we can take away:</h4>
      <h4>
        01 <br />
        Prototyping matters
      </h4>
      <p>
        Prototyping is part of a bigger process that we as designers know as the
        iterative process. The challenge is a great way to explain in a tangible
        way the power of the iterative process.
      </p>
      <h4>
        02 <br />
        Identifying the hidden assumptions of a project
      </h4>
      <p>
        Participants assume that the marshmallows “light” profile won't cause an
        issue the moment the fluffy treat is placed on top of the structure.
        Most of the 18 minutes is spent focused on the structure and not on the
        marshmallow. So at the last minute when participants place the
        marshmallow at the top the “Tah-Dah” moment quickly turns into a “Uh-Oh”
        moment. The marshmallow is a metaphor for the hidden assumptions of a
        project. Identifying and addressing the hidden assumptions in our
        projects will help us reduce those “Uh-Oh” moments.
      </p>
      <div className="why__image-grid-container">
        <img
          id="why__image-1"
          className="why-image"
          src="images/marshmallow-challenge/why-1.jpg"
          alt="Two men building a structure out of spaghetti noodles and marshmallows"
        />
        <img
          id="why__image-2"
          className="why-image"
          src="images/marshmallow-challenge/why-2.jpg"
          alt="A hand in the foreground putting a marshmallow through a spaghetti noodle while a woman watches from the background"
        />
        <img
          id="why__image-3"
          className="why-image"
          src="images/marshmallow-challenge/why-3.jpg"
          alt="A woman standing around desks looking at a laptop "
        />
        <img
          id="why__image-4"
          className="why-image"
          src="images/marshmallow-challenge/why-4.jpg"
          alt="A group of people working together to build a structure out of spaghetti noodles and marshmallows"
        />
        <img
          id="why__image-5"
          className="why-image"
          src="images/marshmallow-challenge/why-5.jpg"
          alt="A close-up view of a structure built out of spaghetti noodles and marshmallows while a group admires it from afar"
        />
        <img
          id="why__image-6"
          className="why-image"
          src="images/marshmallow-challenge/why-6.jpg"
          alt="A group planning around a table on how they will build a structure out of marshmallows and spaghetti noodles"
        />
      </div>
    </div>
  );
};

export default MarshmallowChallenge;
