import MarshmallowChallenge from "../../pages/MarshmallowChallenge/MarshmallowChallenge";

export const projects = [
  {
    id: 1,
    title: "PayLater Alipay x BBVA",
    subtitle: "eCommerce Finance",
    time: 15,
    link: "pay-later-alipay-x-bbva",
    tags: ["unknown"],
  },
  {
    id: 2,
    title: "Marshmallow Challenge",
    subtitle: "Team-building Exercise",
    time: 15,
    link: "marshmallow-challenge",
    tags: ["team_building", "design_challenge", "TEDtalk", "Open_Source"],
    background:
      "The design team at the Dallas Creation Center held biweekly meet-ups to discuss technology, design trends, and other interesting topics. During the meet-up I led, I invited members of both the design and development teams to participate in the Marshmallow Challenge. The exercise is not only a great ice-breaker but a wonderful exercise to illustrate the iterative process.",
    projectInfo: <MarshmallowChallenge />,
  },
  {
    id: 3,
    title: "Texas Parks",
    subtitle: "Service Design Study",
    time: 15,
    link: "texas-parks",
    tags: ["unknown"],
  },
  {
    id: 4,
    title: "Expat's Guide to Commuting to Work",
    subtitle: "Something Fun",
    time: 15,
    link: "expats-guide-to-commuting-to-work",
    tags: ["unknown"],
  },
  {
    id: 5,
    title: "VSE Instructor Dashboard",
    subtitle: "Product Redesign",
    time: 15,
    link: "vse-instructor-dashboard",
    tags: [
      "analysis",
      "journey_map",
      "research",
      "systems_redesign",
      "wireframe_prototype",
    ],
  },
];
