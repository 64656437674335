export const articles = [
  {
    id: 1,
    title: "Skratch - Mentorship program",
    description:
      "I had the priveledge of mentoring the Skratch design team during a collaborative venture sponsored by BBVA Momentum. Some of the areas I was able to help included developing brand guidelines, UI kit, and  identifying experience improvements in the app.",
    link: "https://gemenri.com",
    image: "skratch",
  },
  {
    id: 2,
    title: "Interview with Voyage Dallas",
    description:
      "Sitting down with the online magazine Voyage Dallas was a great deal of fun. Voyage was interested in a project I co-founded with my partner Sean Dorr: Los Dos Creative. I was able to share the creative work Sean and I have colaborated on Los Dos Creative, and parts of my story.",
    link: "https://gemenri.com",
    image: "voyage",
  },
];
