import React from "react";
import ArticleCard from "./components/ArticleCard";
import { articles } from "./constants/articles";
import "./about.scss";

const About = () => {
  return (
    <div className="about__main-container">
      <div className="max-width__container">
        <div className="gutters">
          <h2 className="section-title">Other corners of the net</h2>
          <div className="article-cards__container">
            {articles.map((article) => {
              const { id } = article;
              return <ArticleCard key={id} article={article} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
