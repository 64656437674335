import React from "react";
import PropTypes from "prop-types";
import "./footer.scss";

const Footer = ({ type }) => {
  return (
    <div className={`footer__container ${type}`}>
      <div className="max-width__container">
        <div className="gutters">
          {type === "home" && (
            <h2 className="light-weight">Thanks for stopping by ✌️​</h2>
          )}
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Footer;
