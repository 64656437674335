import React from "react";
import "./welcome.scss";

const Welcome = () => {
  return (
    <div className="welcome__main-container">
      <div className="max-width__container">
        <div className="welcome__content-container">
          <div className="headshot__container">
            <img
              src="images/home-headshot.png"
              alt="Headshot of Gemma Enriquez"
            />
          </div>
          <div className="welcome-text__container">
            <div className="container-line">
              <h4>Welcome 👋</h4>
              <p className="welcome-text__name">My name is Gemma Enriquez.</p>
              <p className="welcome-text">
                I am a UX Designer who's passionate about leveraging emerging
                technology and human-centered design to create memorable
                experiences.
              </p>
              <a className="link about-link" href="https://gemenri.com">
                Get to know me better
              </a>
            </div>
            <p className="container-line texan">
              Texan in Madrid. Curious to see more.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
