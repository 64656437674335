/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import PropTypes from "prop-types";
import LinkIcon from "../icons/LinkIcon";
import "./link.scss";

const Link = ({ className, href, target, children, icon, internal }) => {
  const LinkOnly = () => {
    return (
      <a
        className={`link ${className || ""}`}
        href={href}
        target={internal ? "_self" : "_blank"}
        rel={internal ? "noopener" : "noreferrer"}
      >
        {children}
      </a>
    );
  };

  const LinkWithIcon = () => {
    return (
      <a
        className={`link__container ${className}`}
        href={href}
        target={internal ? "_self" : "_blank"}
        rel={internal ? "noopener" : "noreferrer"}
      >
        <span className="link">{children}</span>
        <LinkIcon />
      </a>
    );
  };

  return !icon ? <LinkOnly /> : <LinkWithIcon />;
};

Link.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.bool,
  internal: PropTypes.bool,
};

Link.defaultProps = {
  className: "",
  href: "#",
  target: "_self",
  children: "Link Text",
  icon: false,
  internal: true,
};

export default Link;
