import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import generateRandomKey from "./utils/functions/generateRandomKey";
import DetailedProject from "./components/DetailedProject/DetailedProject";
import NavBar from "./components/NavBar/NavBar";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import { projects } from "./utils/constants/projects";
import "./styles/main.scss";

const App = () => {
  return (
    <Router>
      <div className="main__container">
        <NavBar />
        <div className="page__content">
          <Routes>
            <Route path="/" index element={<Home />} />
            <Route path="about" element={<About />} />
            {projects.map((project) => {
              const { link } = project;
              return (
                <Route
                  path={`/${link}`}
                  key={generateRandomKey()}
                  element={
                    <DetailedProject
                      project={project}
                      key={generateRandomKey()}
                    />
                  }
                />
              );
            })}
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
