import React, { useState, useEffect } from "react";
import HeroIllustration from "./components/HeroIllustration";
import useWindowSize from "../../../utils/customHooks/useWindowSize";
import "./hero.scss";

const Hero = () => {
  const [illustrationSize, setIllustrationSize] = useState(640);
  const getWindowWidth = useWindowSize().width;

  useEffect(() => {
    if (getWindowWidth > 1700) {
      setIllustrationSize(640);
    } else if (getWindowWidth <= 1700 && getWindowWidth > 1000) {
      setIllustrationSize(480);
    } else if (getWindowWidth <= 1000 && getWindowWidth >= 600) {
      setIllustrationSize(400);
    } else {
      setIllustrationSize(300);
    }
  }, [getWindowWidth]);

  return (
    <div className="hero__container">
      <div className="max-width__container">
        <div className="gutters">
          <h1 className="hero__cta light-weight">
            My
            <br />
            creative corner
            <br />
            of the net
          </h1>
        </div>
      </div>
      <div className="illustration__container">
        <HeroIllustration size={illustrationSize} />
      </div>
    </div>
  );
};

export default Hero;
